import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`about`, `sobre`, `empresa`, `infranology`]}
        title="About"
      />

      <div className="flex flex-col md:flex-row items-center">
        <div className="px-6 md:px-20 md:mx-20">

          <p className="text-4xl font-bold text-primary pb-6">
            Sobre
          </p>

          <p>A <strong>Infranology</strong> foi criada em 2012 com a finalidade de atender as necessidades tecnológicas de um setor ainda embrionário, cujo impacto comercial e estratégico seria de fundamental importância: A Internet.</p>

          <br />
          <p>Por conta desta visão empreendedora a <strong>Infranology</strong> acompanhou e participou ativamente de alguns dos principais projetos em tecnologias aplicadas ao segmento.</p>

          <br />
          <p>A atualização técnica e mercadológica da <strong>Infranology</strong> acompanha as necessidades de um mercado cada vez mais competitivo e exigente.</p>

          <br />
          <p>A <strong>Infranology</strong> tem como missão projetar, desenvolver e implementar soluções sob medida para seus clientes, analisando sua real necessidade nas situações onde a complexidade da tecnologia impede seu avanço, melhorando os resultados e reduzindo custos para clientes, fornecedores e funcionários.</p>

          <div className="py-4">
            <a href="/#">
              <svg
                className="w-10 h-10"
                id="back"
                viewBox="0 -16 370.29 370">
                <path fill="#1ae5be" d="M271 159.15H32.1L83.41 100a10 10 0 00-15.1-13.13l-64.67 74.5a10 10 0 00.47 15.79l64.68 64.67a10 10 0 0014.14-14.11l-48.6-48.57h236.66a10 10 0 000-20zm0 0"/><path fill="#0635c9" d="M330.58.15h-134.2a39.81 39.81 0 00-40.09 39.54v35.74a10 10 0 1020 0V39.8a19.8 19.8 0 0119.95-19.64h134.34a19.47 19.47 0 0119.71 19.23V298.4a19.58 19.58 0 01-19.38 19.76H196.38a19.9 19.9 0 01-20.09-19.7v-34.97a10 10 0 00-20 0v34.9a39.92 39.92 0 0040.08 39.77h134.21a39.6 39.6 0 0039.71-39.49V39.79A39.5 39.5 0 00330.95.15h-.37zm0 0"/>
              </svg>
            </a>
          </div>

        </div>
      </div>
    </Layout>
  );
}

export default AboutPage;
